<template>
  <div class="action-block">
    <div class="common-action dark-gray" @click="showModal">
      <i :class="block.icon"></i> {{ block.name }}
    </div>
    <b-modal id="send-email-modal" v-model="isShowModal" title="Email" size="xl" :no-enforce-focus="true">
      <TextInput v-model="block.name" label="Label" />
      <div class="row">
        <div class="col-md-6">
          <TextInput v-model="block.options.name" label="From name"></TextInput>
        </div>
<!--        <div class="col-md-6">
          <TextInput :value="user.business.twilio_settings.sendgrid_email_address ?? ''" disabled type="email" label="From email" rules="required" name="email"></TextInput>
        </div>-->
        <div class="col-md-12">
          <div class="form-group">
            <label class="d-flex align-center align-items-center"><span class="mr-2">Subject</span><span><MergeFieldsGroup dropdown-size="sm" :show-keyword="true" :show-contact-link="true" :hide-title="true" @selected="appendField" /></span></label>
            <TextInput v-model="block.options.subject" name="subject" rules="required" type="text"></TextInput>
          </div>
          <div class="form-group">
            <label class="d-flex align-center align-items-center"><span class="mr-2">Body</span><span><MergeFieldsGroup dropdown-size="sm" :show-keyword="true" :show-contact-link="true" :hide-title="true" @selected="appendFieldMCE" /></span></label>
            <vue2-tinymce-editor :id="'editor-' + editorId" :ref="'editor-' + editorId" v-model="block.options.message" :options="editorOptions"></vue2-tinymce-editor>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer>
        <div v-if="!hasReviewLink" class="text-danger d-block">You must include the Review link in your message!</div>
        <b-button variant="primary" @click="save">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import MergeFieldsGroup from './MergeFieldsGroup.vue';
import Vue from "vue";
import { Vue2TinymceEditor } from "vue2-tinymce-editor";

export default {
  components: {MergeFieldsGroup, Vue2TinymceEditor},

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      user:{
        business:{}
      },
      isShowModal: false,
      hasReviewLink:true,
      options: [],
      editorId:new Date().getTime(),
      editorOptions:{
        menubar:false,
        plugins: 'advlist autolink charmap code codesample directionality emoticons link',
        toolbar1:'fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor',
        toolbar2:'alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code',
        forced_root_block : false,
        content_style: "body { line-height: 1.2; }",
      }
    }
  },

  computed: {
    showOptions() {
      return this.options && this.options.length > 1
    },
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }
    this.block.options = {
      method: 'Sms',
      images: [],
      ...this.block.options
    }
  },

  methods: {
    save() {
      this.hasReviewLink = this.block.options.message.includes("[review_link]");
      if(this.hasReviewLink) {
        this.isShowModal = false
      }
    },

    showModal() {
      this.isShowModal = true
      // this.getSelectOptions()
    },
    getSelectOptions() {
      this.$store.dispatch('automation/selectOptions')
          .then(options => {
            this.options = options
            if (options && options.length === 1)
            {
              this.block.options.integration_user = options[0]
            }
          })
          .catch(() => { })
    },
    appendField(field) {
      document.execCommand('insertText', false, field)
    },
    appendFieldMCE(field){
      if(field === '[review_link]'){
        field = `<a href="${field}">Review Us</a>`;
      }
      const editor = this.$refs['editor-' + this.editorId];
      if (editor) {
        // Insert text at the current cursor position
        editor.editor.selection.setContent(field);
        this.$set(this.block.options,'message',editor.editor.getContent())
      }
    }
  }
}
</script>

<style lang="scss">
#send-email-modal .modal-dialog{
  max-width: 600px;
}
</style>
